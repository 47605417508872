import React from 'react';
import './Project-Viewer.css';
import './flexbin.css';
import mira_logo from './img/mira-logo-mini.png';
//import './App.css';

var mira_logo_bg = {backgroundImage: `url(${mira_logo})`};






/*function Project() {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  return (
    <div className="Project-viewer">
      
    </div>
  );
}*/
/*
var ProjectViewer = React.createClass({
  propTypes: {
    // the StyledButton requires a clickHandler
    clickHandler: React.PropTypes.func.Required,
    // and I guess the text can be seen as required as well
    text: React.PropTypes.string.required
  },
  render: function() {
    // as you are sure you have a clickHandler, you can just reference it directly from the props
    return <button type="button" onClick={this.props.clickHandler}>{this.props.text}</button>;
  }
});*/

class ProjectViewer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    
    
    var project_data = this.props.project_data;
    if(!this.props.project_data){
      return false;
    }

    if(this.props.project_data.hasOwnProperty('CaseStudy')){
      console.log(this.props.project_data.CaseStudy);
    }
    

    var work_overview = '';
    if(project_data.hasOwnProperty('WorkSummary')){
      var items = project_data.WorkSummary.map((item, key) =>
      <li key={key}>
        <div className="List-number">{key+1}</div>
        <div className="Name">{item.Title}</div><br/>
        <div className="Description">{item.Description}</div>
      </li>);

      work_overview = (
      <div className="Half-section">
        <h2>
          Work Overview
        </h2>
        <ul className="List Sub-services">
        {items}
        </ul>
      </div>);

    }

    //<img src={"./img/projects/"+this.props.current_project+"/"+item}/>
    var case_study = '';
    if(project_data.hasOwnProperty('CaseStudy')){
      var items = Object.keys(project_data.CaseStudy).map((item, key) =>
      <a href="#">
        <img src={project_data.CaseStudy[item]}/>
      </a>);

      case_study = (
        <section className="Project-section">
        <h2>
          Project Images
        </h2>
        <div class="flexbin flexbin-margin">
        {items}
        </div>
      </section>);

    }


    var video_player = '';
    if(project_data.hasOwnProperty('VideoID')){
      video_player = (
        <iframe width="100%" height="520" src={"https://www.youtube.com/embed/"+project_data.VideoID} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>);
    }





    return <div className={"Project-viewer"+" "+this.props.projectIsOpen}>
      <div className="Background" onClick={(e) => this.props.toggleProject(e,false)}></div>
      <div className="Page-container">
        <div className="Project-container">
          <header className="Project-header" style={{backgroundColor:"#"+project_data.ProjectColor}}>
            <div className="Title">
              <div className="Name"><div className="Logo" style={{backgroundImage: `url(${this.props.project_images[this.props.current_project+"_logo.png"]})`}}></div>{project_data.Name}</div>
            </div>
            <img className="Img" src={this.props.project_images[this.props.current_project+"_thumb.png"]}/>
            <div className="Description">{project_data.Description}</div>
          </header>
          <section className="Project-section">
            {video_player}
          <div className="Half-section">
            <h2>
              The Brief
            </h2>
            <div className="Description">{project_data.Brief}</div>
          </div>
          {work_overview}
          </section>
          {case_study}
        </div>
      </div>
    </div>;
  }
}


export default ProjectViewer;
