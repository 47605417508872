import React from 'react';
import ReactDOM from 'react-dom';
//import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
//import { useHistory } from "react-router-dom";


// PROJECT FILES /////////////////////////////////////////////
import Project_Viewer from './Project-Viewer.js';
import Product_sketch_svg from './Product-sketch.js';
import About from './About.js';


// PROJECT CSS /////////////////////////////////////////////
import './App.css';


// PROJECT IMG /////////////////////////////////////////////
//import logo from './img/hellopress-logo.svg';
import logo from './img/Hellopress_logo_ring.svg';
import Past_Clients from './img/client_logos.png';
import Past_Clients_Mobile from './img/client_logos_mobile.png';
import cover_img from './img/product_design_cover.png';
//import product_sketch from './img/product_sketch_blue_cropped.png';
//import product_sketch from './img/Product_sketch_no_namespace.svg';
import video from './video/REEL_CUT_2.mp4';


// HEADSHOTS /////////////////////////////////////////////
import Christopher_headshot from './img/Christopher.jpeg';
import Colin_headshot from './img/Colin.jpeg';
import Ryan_headshot from './img/Ryan.jpeg';
import Naveed_headshot from './img/Naveed.jpeg';
import Alessandro_headshot from './img/Alessandro.jpeg';
import Robert_headshot from './img/Robert.jpeg';
import Bamidele_headshot from './img/Bamidele.jpeg';
import Gala_headshot from './img/Gala.jpeg';



/*
//Array Import, not needed.

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./img/projects/', false, /\.(png|jpe?g|svg)$/));*/

const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

const Project_Images = importAll(
  require.context("./img/projects", false, /\.(png|jpe?g|svg)$/)
);

const Images = importAll(
  require.context("./img", false, /\.(png|jpe?g|svg)$/)
);

const Mira_Images = importAll(
  require.context("./img/projects/Mira", false, /\.(png|jpe?g|svg|gif)$/)
);





////////////////////////////////////
// MEMBERS /////////////////////////
////////////////////////////////////

var Team = {
  Christopher:{
    Name:"Christopher Paniati",
    Job_Title: "Product Designer",
    Photo:Christopher_headshot,
    Bio:"Christopher is a product designer with experience building digital products & services from the ground up. Christopher has lead product design teams at Mira A.I. and Autodesk, as well as helping build & launch a variety of websites, apps, and games over the past decade."
  },
  Ryan:{
    Name:"Ryan D'Amico",
    Job_Title:"Motion Designer",
    Photo:Ryan_headshot,
    Bio:"Ryan is a motion designer with experience bringing animation & film projects to life. Ryan has worked on everything from TV advertisements to mobile app animations to major motion picture projects and everything in between."
  },
  Alessandro:{
    Name:"Alessandro Battisti",
    Job_Title:"Product Designer & Strategist",
    Photo:Alessandro_headshot,
    Bio:"Alessandro is well-versed in both product design and design strategy. Alessandro has worked with many of the top consumer brands to develop innovative product offerings, and deliver actionable insights to improve their products & services."
  },
  Colin:{
    Name:"Colin Swenson",
    Job_Title:"Design Researcher",
    Photo:Colin_headshot,
    Bio:"Colin Swenson is a talented researcher with a background in design consulting, and has lead research projects for big corporate clients as well as small businesses. Colin excells in crafting & executing research plans that empower teams to build with confidence."
  },
  Gala:{
    Name:"Gala Tarang",
    Job_Title:"Product Designer",
    Photo:Gala_headshot,
    Bio:"Gala is a product designer who has taken startups from humble beginnings to Series A and beyond. Gala was the creative lead at Way2B1, and has worked with tech companies big and small to go from research to finished product."
  },
  Robert:{
    Name:"Robert Bennett",
    Job_Title:"Cinematographer",
    Photo:Robert_headshot,
    Bio:"In addition to his incredible cinematography work, Robert has skills in film directing & producing, film editing, photography, and much, much more. Robert is a get-it-done, one-of-a-kind contributor with the skills to take any video project from start to finish."
  },
  Bamidele:{
    Name:"Bamidélé Elégbèdé",
    Job_Title:"Industrial Designer",
    Photo:Bamidele_headshot,
    Bio:"From electric motorcycles to vaporizers, Bamidélé is a one-of-a-kind industrial designer. He's helped launch projects big and small, translating human needs and behaviors into inventive & intuitive products."
  },
  Naveed:{
    Name:"Naveed Nadjmabadi",
    Job_Title:"Full-Stack Developer & Data Engineer",
    Photo:Naveed_headshot,
    Bio:"Naveed has a decade of experience leading teams in developing software projects across many industries. Naveed is our go-to advisor for feasibility & development expertise. "
  }
};





////////////////////////////////////
// PROJECTS ////////////////////////
////////////////////////////////////

var Projects = {
  Video_Reel:{
    Name:"Hellopress Video Reel",
    ProjectColor:"DD1B82",
    VideoID:"vGfpKVdJg2Y",
    Description:"We partnered with design strategy firm The Wrecking Crew to help T-Mobile create an offering for small businesses. The Hellopress team created design assets, planned and ran co-creation projects, and delivered insights to the T-Mobile team by recording research process into a film deliverable.",
    Brief:"T-Mobile realized that when it came to the mobile services market, small businesses were an underserved demographic. The T-Mobile team wanted to understand if there was an opportunity to better serve this audience, and what the ideal services offering could look like.",
    WorkSummary:[
      {
        Title:"Product Research",
        Description: "Recruited & interviewed small-business owners to gather initial insights surrounding existing pain points & opportunities."
      },
      {
        Title:"Co-Creation Lab",
        Description: "Planned & executed multiple co-creation sessions involving SMB owners and T-Mobile stakeholders to collaboratively refine the new offerings."
      },
      {
        Title:"Product Development",
        Description: "Turned research insights into concepts to formally pitch new service offerings to T-Mobile executives."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  },
  T_Mobile:{
    Name:"Small Business Unlimited",
    ProjectColor:"DD1B82",
    VideoID:"NvdAJC_ZDIU",
    Description:"We partnered with design strategy firm The Wrecking Crew to help T-Mobile create an offering for small businesses. The Hellopress team created design assets, planned and ran co-creation projects, and delivered insights to the T-Mobile team by recording research process into a film deliverable.",
    Brief:"T-Mobile realized that when it came to the mobile services market, small businesses were an underserved demographic. The T-Mobile team wanted to understand if there was an opportunity to better serve this audience, and what the ideal services offering could look like.",
    WorkSummary:[
      {
        Title:"Product Research",
        Description: "Recruited & interviewed small-business owners to gather initial insights surrounding existing pain points & opportunities."
      },
      {
        Title:"Co-Creation Lab",
        Description: "Planned & executed multiple co-creation sessions involving SMB owners and T-Mobile stakeholders to collaboratively refine the new offerings."
      },
      {
        Title:"Product Development",
        Description: "Turned research insights into concepts to formally pitch new service offerings to T-Mobile executives."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"NvdAJC_ZDIU"
    }
  },
  Mira:{
    Name:"Mira A.I. – Beauty Search Engine",
    ProjectColor:"594BED",
    Description:"Mira is a search engine & community platform for makeup and beauty products. We worked with Mira from the start all the way through their product launch 2 years later. We lead design efforts throughout the entire process from early user research & product direction to marketing materials and content development.",
    VideoID:"33NcQarTNKk",
    //CaseStudy:Mira_Images,
    Brief:"Over the past decade digital technology has revolutionized nearly every big consumer industry, but one that's been slow to adopt is the world of beauty & makeup. Mira wanted to use modern ML & data science techniques to help users make more informed purchasing decisions. We helped Mira get from this early idea to the launched product & platform that it runs today.",
    WorkSummary:[
      {
        Title:"Product Research",
        Description: "Conducted interviews, lead co-creation labs, and synthesized findings in order to explore & evaluate potential product directions for Mira."
      },
      {
        Title:"UI Design",
        Description: "Created UI designs using Figma for Mira's mobile app & web platform. Worked with development team to evaluate & implement designs & functionality"
      },
      {
        Title:"Brand Development",
        Description: "Leading up to the launch, we helped develop a more mature, prime-time-ready brand for Mira. We worked with branding firm C42D to explore branding concepts, and then we took it from there to finalize the logo, develop color schemes, and apply the new branding across the product."
      },
      {
        Title:"Pitch Materials",
        Description: "Throughout fundraising, we developed & iterated pitch deck slides. We turned around designs on a tight timeline and iterated our visuals & approach after feedback from each pitch."
      },
      {
        Title:"Marketing Strategy & Materials",
        Description: "We worked closely with Mitch Orkis to build out Mira's marketing strategy for launch & beyond. We created, managed, and tested instagram advertisements to maintain a consistent inflow of new users into the platform."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"33NcQarTNKk"
    }
  },
  Tin_Pan:{
    Name:"Tin Pan – Musical Improv",
    ProjectColor:"B4737A",
    Description:"Tin Pan started as a passion project by seasoned app developer Gil Carmel following his venture into learning guitar. Could a music theory-based interface allow beginners to use play and improvisation as a way of learning the higher level music concepts?",
    VideoID:"Xyw2vHd1rx4",
    Brief:"Tin Pan's founder Gil came to us with an idea for a new kind of music improvisation app for the iPad that would let users learn to compose their own music. Our team designed the interface & interactions within the app, as well as developing the branding & promotional materials.",
    WorkSummary:[
      {
        Title:"Product Research",
        Description: "We worked with Gil to conduct user research with his target audience in order to better understand the process of learning to play & compose music."
      },
      {
        Title:"UI Design & Branding",
        Description: "We created UI Designs for the Tin Pan app, and worked with Gil to implement the designs in the App itself."
      },
      {
        Title:"Promo Material Development",
        Description: "We created promotional materials for Tin Pan including a teaser video and launch website."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"Xyw2vHd1rx4"
    }
  },
  Autodesk:{
    Name:"Autodesk Atlas – UX Research Tool",
    ProjectColor:"5F5F5F",
    Description:"We worked with Autodesk to create an develop an internal journey mapping web app to support their UX processes throughout the company. We led front-end development of the tool along with product design & strategy. Partnered with Germinate Apps who led the back-end development of the tool.",
    VideoID:"ONksH5eHa_Q",
    Brief:"In 2015 Autodesk was in a transitional stage – Autodesk offered a suite of nearly 100 differnt software products. Many were brought in through acquisitions and had disparate UI experiences. Additionall, Autodesk was the process of moving its applications to the cloud. Amid all of this transition, there was a big need for UX process improvement to identify & address customer pain points & product issues.",
    WorkSummary:[
      {
        Title:"User Research",
        Description: "Conducted interviews with Autodesk employees, stakeholders, and customers in order to understand their needs & processes. Delivered insights to stakeholders."
      },
      {
        Title:"Concept Exploration",
        Description: "Journey-mapping was identified as a key valuable process, but had barriers to its usage within the company. Developed concepts & prototypes to explore different ways of enabling UX research & communication."
      },
      {
        Title:"Product Pitch",
        Description: "Ultimately we received interest surrounding the possibility of a web platform for customer journey mapping. We built a prototype of the tool and pitched to internal stakeholders for funding."
      },
      {
        Title:"Product Design & Development",
        Description: "After recieving funding for the development of the journey mapping tool, we contracted a development team who we worked with to build the tool. We led product direction & evolution, as well as designin & building the front-end of the web app."
      },
      {
        Title:"Product Adoption & Promotion",
        Description: "Within the company, we led efforts to promote the usage of the platform. We worked with users to explore a variety of use cases for the tool and share those processes with others within the company."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  },
  Cake:{
    Name:"Cake for Men – Sexual Wellness",
    ProjectColor:"E97D91",
    Description:"As Cake was in the process of fundraising, they partnered with use to offer design support in the creation of promotional & brand materials. We developed designs for instagram advertisements, a launch website, and edited & animated their launch announcement video that the founders had recorded",
    VideoID:"zmDTvF1zszs",
    Brief:"Cake wanted to get promotional materials out the door as quickly as possible. They were still in the process of developing their branding, and needed intermediate assets that leveraged & built on their in-the-works brand materials.",
    WorkSummary:[
      {
        Title:"Advertisement Development",
        Description: "Developed a set of instagram ads to launch their campaign & test different potential product lines."
      },
      {
        Title:"Launch Site Design",
        Description: "Turned around a 2-day design for their launch site that worked with their engineering & platform constraints."
      },
      {
        Title:"Video Editing & Animation",
        Description: "We edited and animated the launch announcement video that the founders recorded and sent to us."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"zmDTvF1zszs"
    }
  },
  Playpen:{
    Name:"Playpen – Smart Dog Toy",
    ProjectColor:"27242C",
    Description:"Woof! Playpen is a concept for a new type of dog-human play experience that we researched, prototyped, and pitched to investors. The concept utilizes the Sphero robotic ball and introduces the playpen smart hub to create a game that can be played between the human and dog in the home.",
    VideoID:"4OFSBCSSTlE",
    Brief:"In 2015, Americans spent $40 billion to keep their dogs happy, but one of the biggest pain points for dogs & owners remains unsolved… How can dog owners stay connected with their dogs while away from home?",
    WorkSummary:[
      {
        Title:"Product & Market Research",
        Description: "Conducted market research to assess the viability of a product in the dog-toy market space."
      },
      {
        Title:"Prototyping & Testing",
        Description: "Built & tested prototypes of the playpen smart-hub treat dispenser in order to asses the efficacy of the game with dogs."
      },
      {
        Title:"Promotional Materials & Assessment",
        Description: "Developed promotional marketing materials including video & website in order to generate & assess target audience interest in the product."
      },
      {
        Title:"Pitching & Fundraising",
        Description: "Pitched the product to investors to get seed funding for the project (ultimately decided not to pursue because of feasibility challenges with tech immaturity in device tracking precision)."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  },
  MADA:{
    Name:"Make America Doodle Again",
    ProjectColor:"453288",
    Description:"A social drawing game where you and a buddy both draw a different presidential candidate's stance on a political issue. You then have one minute to draw and then guess each other’s drawings. This game was designed to encourage civic engagement in the 2016 presidential election.",
    VideoID:"ZsqKt6jy020",
    Brief:"The 2016 election cycle was crazy! Did we even really know what a candidates’ stance was on x, y, or z? Our goal was to get people thinking about the candidates’ actual positions on issues in a fun and engaging way before the election so they felt prepared to vote on November 8th. After a concept exploration we arrived at a novel version of pictionary designed to encourage political discussion. We led a team of designers to translate the game into a digital experience & online presence. We used firebase and react.js to develop the web-based version of the game and launched it at www.makeamericadoodleagain.com.",
    WorkSummary:[
      {
        Title:"User Research",
        Description: "Conducted interviews, surveys, and participatory reserach to understand peoples needs, behaviors, and motivations surrounding political discussions."
      },
      {
        Title:"Product Design & Testing",
        Description: "We designed & tested various iterations of a political discussion game through both digital prototypes and in-person mock testing sessions."
      },
      {
        Title:"Product Development",
        Description: "We built & launched the final product, an online game accessible via our web app where users could create & submit their political drawings."
      },
      {
        Title:"Promotional Event",
        Description: "We helped launch & promote the product by hosting a kick-off event at San Francisco's Dolores Park which brought in more than 600 drawings in one day."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  },
  Spirit_Fruit:{
    Name:"Spirit Fruit Co.",
    ProjectColor:"453288",
    Description:"We partnered with Konstantin Kosov for a rebrand & relaunch of his fruit delivery business as he transitioned from business-delivery to residential delivery.",
    VideoID:"QfLwfmFyS7A",
    Brief:"When Covid-19 hit, Konstantin's business delivering fruit to companies in SF was hit hard and he lost almost all of his clients. We worked with Konstantin to build a residential-oriented produce delivery model bringing farmer's market produce to people across the bay. From April-July 2020 we helped his business acquire over 400 new clients and even open up a new delivery service to serve the East Bay.",
    WorkSummary:[
      {
        Title:"User Research",
        Description: "Conducted interviews, surveys, and participatory reserach to understand the fruit delivery landscape and customer needs."
      },
      {
        Title:"Branding",
        Description: "We built a new brand with the name Spirit Fruit, and developed a logo, graphics, and imagery."
      },
      {
        Title:"Marketing",
        Description: "We developed flyers, photography, promotional codes, and social media content to help market Spirit Fruit."
      },
      {
        Title:"Website Development",
        Description: "We built & launched a new website for Spirit Fruit, with an easy-to-use ordering system, as well as CMS for managing orders."
      },
      {
        Title:"Operations Development",
        Description: "We worked with Konstantin to optimize his delivery operations and improve customer communication within his service."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"QfLwfmFyS7A"
    }
  },
  Mayven:{
    Name:"Mayven – Logo Animation",
    ProjectColor:"000000",
    Description:"We worked with Mayven to develop an animated version of their existing logo. Mayven came to us with the basic concept and we worked with them to refine the animation and ultimately develop the full deliverable using Adobe After Effects.",
    VideoID:"Hs3DJCPgzlk",
    Brief:"Mayven needed an animated version of their logo to use within their promotional content. They wanted something simple & straightforward that worked with their existing brand",
    WorkSummary:[
      {
        Title:"Animation Concepting",
        Description: "Worked with Mayven to create logo animation concepts, then evaluate concepts to arrive at a final direction."
      },
      {
        Title:"Animation Development",
        Description: "Built the final animated logo using Adobe After Effects."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  },
  EM2:{
    Name:"EM – Journaling Device",
    ProjectColor:"CC5C5E",
    Description:"This first video was created as part of an exploration into conversational journaling. Conversational journaling leverages new language processing technology along with an emotive tonal response system to create a sense of dialog that increases the excitement and engagement for users.",
    VideoID:"S26LW8WgXTA",
    Brief:"This first video was created as part of an exploration into a conversational journaling concept. The goal with the video was to demonstrate the user experience & functionaity of a wearable journaling device. The video was used to get input & buy-in from key project stakeholders.",
    WorkSummary:[
      {
        Title:"User Research",
        Description: "Recruited & interviewed small-business owners to gather initial insights surrounding existing pain points & opportunities."
      },
      {
        Title:"Product Prototyping",
        Description: "Used arduino to prototype the journaling device functionality & tested with users. 3D printed a custom necklace enclosure for the wearable device."
      },
      {
        Title:"UX Demonstration",
        Description: "Created a video demonstrating potenial usage & functionality of the wearable journaling device in order to get stakeholder input & investment."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  },
  EM:{
    Name:"EM – Conversational Journal",
    ProjectColor:"CC5C5E",
    Description:"Today's world is complex. Even just managing our interpersonal relationships can be tough. Journaling has been proven to be an effective tool for reflection & instrospection that helps us keep up with our increasingly busy lives. EM provides an accessible, conversational web platform that makes journaling easy.",
    VideoID:"jqxYS-ToVDo",
    Brief:"This second video was created as part of the development of the EM web platform for conversational journaling. The goal of the video project was to highlight EM's conversational journaling functionality. In particular, we wanted to convey to viewers what the experience of using EM would be like, while also demonstrating a variety of use cases and key personas. ",
    WorkSummary:[
      {
        Title:"User Research",
        Description: "Conducted research into journaling & therapeutic techniques and tested with representative users."
      },
      {
        Title:"Product Prototyping & Testing",
        Description: "Developed concept journaling prototypes & tested with target users to inform key product decisions & platform functionality."
      },
      {
        Title:"Platform Design & Development",
        Description: "Designed and developed the EM web platform. Implemented a speech recognition system + NLP to process input, along with an emotive response system to create a sense of dialog."
      },
      {
        Title:"Pitch Materials",
        Description: "Developed pitch deck & promotional video in order to demonstrate the concept & experience to potential user & investors."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  },
  Gracie:{
    Name:"Gracie Jiu Jitsu",
    ProjectColor:"282828",
    Description:"We worked with James to develop a website, logo, and other promotional materials for his Jiu Jitsu gym in Savannah, GA.",
    VideoID:"rKBv_z2dHLs",
    Brief:"James was opening a new jiu jitsu gym in Savannah, GA and he needed a way to attract and retain new clients. We developed a website & brand materials that included a space for him to post weekly updates and share new content with his existing customers to keep them engaged and coming back each week.",
    WorkSummary:[
      {
        Title:"Branding",
        Description: "Created branding for the gym, for his website, and an animated logo for use in his youtube videos."
      },
      {
        Title:"Website",
        Description: "Designed and built a website & blog for James to attract new clients and stay connected with existing ones by posting new content each week."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  },
  Rent_Control:{
    Name:"AHF – Rent Control Ad",
    ProjectColor:"2499F3",
    Description:"Rent prices are astounding in much of California where they make affordable housing nearly impossible to find in much of the state. AHF is working to pass a new bill that will expand rent control provisions in order to help protect renters. We partnered with the Aids Health Foundation to create advertisements promoting the rent control bill they are trying to pass.",
    VideoID:"B1MUMcN_ERU",
    Brief:"AHF came to us to partner on developing a series of advertisements to promote public awareness of the bill & its implications. We provided input and direction on the avertisements, and ultimately created the animations to compliment the video & recordings they had shot.",
    WorkSummary:[
      {
        Title:"Ad Concept Exploration",
        Description: "Worked with AHF to help them thinking through different ad concepts and feasibility."
      },
      {
        Title:"Animated Advertisements",
        Description: "Delivered a set of animated advertisements buiding on the existing video recordings that AHF provided."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"B1MUMcN_ERU"
    }
  },
  Rent_Control2:{
    Name:"AHF – Rent Control Ad",
    ProjectColor:"84B9E3",
    Description:"Rent prices are astounding in much of California where they make affordable housing nearly impossible to find in much of the state. AHF is working to pass a new bill that will expand rent control provisions in order to help protect renters. We partnered with the Aids Health Foundation to create advertisements promoting the rent control bill they are trying to pass.",
    VideoID:"B1MUMcN_ERU",
    Brief:"AHF came to us to partner on developing a series of advertisements to promote public awareness of the bill & its implications. We provided input and direction on the avertisements, and ultimately created the animations to compliment the video & recordings they had shot.",
    WorkSummary:[
      {
        Title:"Ad Concept Exploration",
        Description: "Worked with AHF to help them thinking through different ad concepts and feasibility."
      },
      {
        Title:"Animated Advertisements",
        Description: "Delivered a set of animated advertisements buiding on the existing video recordings that AHF provided."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"B1MUMcN_ERU"
    }
  },
  Love_Condoms:{
    Name:"Love Condoms – Ad",
    ProjectColor:"DC4946",
    Description:"We worked with AHF to create a series of instagram ads promoting their new condom and sexual health products brand, Love.",
    VideoID:"hcCDGogZqQU",
    Brief:"The Aids Health Foundation needed to get the word out about their new sexual health products brand, Love. We took photos of their new products and animated them into short video ads that could be used across social media platforms.",
    WorkSummary:[
      {
        Title:"Animated Advertisements",
        Description: "Created a series of short, animatedinstagram advertisements showing off Love's new brand & products."
      }
    ],
    MainContent:{
      Type:"Video",
      VideoID:"4OFSBCSSTlE"
    }
  }
};





////////////////////////////////////
// NAV SECTIONS ////////////////////
////////////////////////////////////

//Great products are really about people. Its about discovering what your people need, what they crave, and what offers the seductive appeal that captivates and engages them. Powerful storytelling and strategic marketing are the oft-neglected ingredients that transform a good idea into a great product.
//,"Branding","Website"

var sections = {
  About:{
    Title:'A Product-First Design Studio',
    Description:"Buiding great products is really about people. Its about uncovering what your people need, how you can provide it, and crafting the captivating narrative that connects the two. Powerful storytelling, effortless design, and strategic marketing are the key ingredients that transform a good idea into a great product.",
    BodyHeaderText:'Check Out Our Work',
    Sections:["Product","Marketing","Film"]
  },
  Product:{
    Title:'Product Design',
    Description:"<b>Great product design is multidisciplinary.</b> With good product research, you'll know which value propositions to highlight in your ads. Well-informed ads will enhance the user's onboarding experience. And a strong set of metrics will allow you to know what's working across the board.<br/><br/>Rather than segregating the product, design, and marketing, we bring them together and look at how they can compliment one another. Our emphasis on product allows us to deliver top-tier results in everything we do.",
    Services:[
      {
        Title:'Product Research',
        Description:'Conduct user research to analyze what’s working and what’s not with your product. Synthesize the insights that will inform your next phase of development.'
      },
      {
        Title:'Product Strategy',
        Description:'Turn insights into opportunities. Develop a plan to address product gaps and get to the next level.'
      },
      {
        Title:'UI / UX Design',
        Description:'Simplify, beautify, and expand your product. Redesign your customer journey.'
      },
      {
        Title:'Product Rebrand',
        Description:"Get your product ready for prime time with a rebranded interface, messaging, and content."
      }
    ],
    BodyHeaderText:'Our Product Design Work',
    Projects:["Mira","Autodesk","Tin_Pan","T_Mobile","EM"],
    Featured:["Mira","T_Mobile"]
  },
  Marketing:{
    Title:'Marketing & Ads',
    Description:"<b>Marketing is hard because it is many things at once.</b> It’s defining what customer we’re pursuing and how many of them exist. It’s directing where and how we find them. It’s measuring and improving ways to find more of them. It’s helping evolve our products in response.<br/><br/>We take an analytical approach to advertising by leveraging metrics, testing, and feedback to identify which value propositions will work best for your audience. Then, we take those insights and turn them into a marketing campaign that you can run yourself.",
    Services:[
      {
        Title:'Market Research',
        Description:'Gather & synthesize audience insights. Improve your product by improving your target market intel.'
      },
      {
        Title:'User Acquisition Strategy',
        Description:'Build a plan to get customers in the door without breaking the bank.'
      },
      {
        Title:'Ad & Content Creation',
        Description:'Create & implement data-backed ads & content to support your marketing efforts.'
      },
      {
        Title:'Product Launch & Web',
        Description:"Create the messaging, channels, and content you need to launch your product the right way."
      }
    ],
    BodyHeaderText:'Our Marketing Work',
    Projects:["Mira","Tin_Pan","Cake","Love_Condoms","Rent_Control"],
    Featured:["Cake","Rent_Control"]
  },
  Film:{
    Title:'Brand & Storytelling',
    Description:"<b>Great storytelling is at the foundation of every great brand.</b> From TV to Youtube, Instagram to Tik Tok, the formats for storytelling are constantly evolving. This increasingly complex digital landscape is changing the way that we explore, discover, and connect with new products. And its changing the ways that stories are being told. <br/><br/>By connecting the entire process, our team pairs the emotional storytelling in our ads with the practical storytelling in our product. Our film & animation team creates the emotional assets that power our marketing campaigns, turning viewers into users and adopters into advocates.",
    Services:[
      {
        Title:'Brand Development',
        Description:"Build a brand that reflects your audience's values and embraces the best parts of your product."
      },
      {
        Title:'Tell Your Story',
        Description:'Whether its a video ad, product explainer, or investor pitch we’ve got you covered.'
      },
      {
        Title:'Motion Design',
        Description:'Build a motion design toolkit & assets to bring your product to life.'
      },
      {
        Title:'Visual Assets & Photography',
        Description:'Editorial graphics, catalog photos, or even team headshots, we’ll get it done.'
      }
    ],
    BodyHeaderText:'Our Film & Animation Work',
    Projects:["MADA","Mira","Tin_Pan","Cake","T_Mobile","EM","Playpen","Autodesk","Rent_Control","Mayven","Gracie"],
    Featured:["Spirit_Fruit","Playpen"]
  },
  Team:{
    Title:'Meet the minds behind our design',
    Description:"Hellopress is a multidisciplinary team of designers, researchers, and creators. Each of us brings a unique perspective to our work, giving us the flexibility take each angle of the project into account. Our work is collectively empowered by a dedication to rigor, an emphasis on play, and a love of strong coffee. <i>☕</i>",
    Team_Members:Team,
    BodyHeaderText:'Our Advertising Work'
  }
};


//Without good product research, you won’t know which features to highlight in your ads. And without good metrics, you won’t be able to properly measure the efficacy of your marketing efforts. Our emphasis on product allows us to deliver top-tier results in everything we do.

//Good branding tells a story that their users feel like they already know. Great branding lets the user that story through the product. <br/><br/>From television to youtube, to instagram, the formats for digital storytelling are constantly evolving.  changing the way that we explore, discover, and connect with new products. Our team pairs film & animation team creates the emotional assets that power our marketing campaigns and turn viewers into customers into adopters, and users into advocates.

/*
,
  Branding:{
    Title:'Branding',
    Description:'From television to youtube, to instagram, video is constantly evolving the way that we explore, discover, and connect with new products. Our film & animation team creates the emotional assets that power our marketing campaigns and turn viewers into customers.',
    Services:[
      {
        Title:'Brand Strategy',
        Description:'We’ll help you find the perfect match between your product and your audience.'
      },
      {
        Title:'Logo Development',
        Description:'We’ll work with you to create a logo & core brand assets.'
      },
      {
        Title:'Branding Toolkit',
        Description:'Our design team will help you build a brand toolkit to transform your product.'
      }
    ],
    BodyHeaderText:'Our Branding Work',
    Projects:["Mira","Tin_Pan","Cake","EM","Playpen","Autodesk","Gracie"],
    Featured:["Tin_Pan","Mira"]
  },
  Website:{
    Title:'Web Design & Dev.',
    Description:'From television to youtube, to instagram, video is constantly evolving the way that we explore, discover, and connect with new products. Our film & animation team creates the emotional assets that power our marketing campaigns and turn viewers into customers.',
    Services:[
      {
        Title:'Web Design',
        Description:'We’ll create responsive, mobile-first designs for a site that will move the masses.'
      },
      {
        Title:'Landing Page Development',
        Description:'A landing page is to a product, what a cover is to a book. We’ll help build yours.'
      }
    ],
    BodyHeaderText:'Our Website Work',
    Projects:["Mira","Tin_Pan","Cake","EM","Autodesk","Gracie"],
    Featured:["Mira","EM"]
  },*/


////////////////////////////////////
// REACT APP FUNCTIONS /////////////
////////////////////////////////////

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectIsOpen: false,
      currentPage: "About",
      currentProject: false
    };
  }
  componentDidMount(){
    

    //check page URL for page assignment when page is loaded
    if(sections.hasOwnProperty(window.location.pathname.replace("/", ""))){
      console.log('valid page!!!');
      var elmnt = document.getElementById("Nav_wrapper");
      elmnt.scrollIntoView();
      this.setState({currentPage:window.location.pathname.replace("/", "")});
    }else if(Projects.hasOwnProperty(window.location.pathname.replace("/", ""))){
      console.log('valid project!!!');
      this.setState({currentProject:window.location.pathname.replace("/", ""), projectIsOpen:true});
      console.log(window.location.pathname.replace("/", ""));
    }
      
      //detect URL change
      window.addEventListener("popstate", function(e) {
        
        //if user navigates back to home
        if(window.location.pathname == "/"){
          var elmnt = document.getElementById("Nav_wrapper");
          elmnt.scrollIntoView();
          this.setState({currentPage:"About", projectIsOpen:true, currentProject:false},function(){checkPosition();});
        }

        //navigate to correct page
        console.log('url change');
        if(sections.hasOwnProperty(window.location.pathname.replace("/", ""))){
          console.log('valid page!!!');
          var elmnt = document.getElementById("Nav_wrapper");
          elmnt.scrollIntoView();
          this.setState({currentPage:window.location.pathname.replace("/", ""), projectIsOpen:true, currentProject:false},function(){checkPosition();});
        }else if(Projects.hasOwnProperty(window.location.pathname.replace("/", ""))){
          console.log('valid project!!!');
          this.setState({currentProject:window.location.pathname.replace("/", ""), projectIsOpen:true},function(){checkPosition();});
        }
      }.bind(this));
  }
  toggleProject(event,item) {

    console.log('toggling project');
    if(!this.state.projectIsOpen){
      setURL(item);
      this.setState({
        projectIsOpen: true,
        currentProject: item
      });
      document.getElementsByTagName("body")[0].classList.add("project-open");
      console.log('Opening Project...');
      
    }else{
      setURL(this.state.currentPage);
      this.setState({
        projectIsOpen: false,
        currentProject:false
      });
      document.getElementsByTagName("body")[0].classList.remove("project-open");
      console.log('Closing Project...');
    }

  }
  changePage(section,e){
    if(section != this.state.currentPage){
      if(window.scrollY < (document.getElementById("Nav_wrapper").offsetTop - 200)){
        var elmnt = document.getElementById("Nav_wrapper");
        elmnt.scrollIntoView();
      }
      if(document.getElementById("App-body")){
        var service_content = document.getElementById("App-body");
        service_content.classList.add("faded");
      }
      
      setTimeout(() => {
        this.setState({
          currentPage: section
        });
        setURL(section);
        if(document.getElementById("App-body")){
          document.getElementById("App-body").classList.remove("faded");
          checkPosition();
          var elmnt = document.getElementById("Nav_wrapper");
          elmnt.scrollIntoView();
        }
      }, 500);
    }else{
      var elmnt = document.getElementById("Nav_wrapper");
      elmnt.scrollIntoView();
    }
    console.log('Changing Page...');
  }
  render() {

    // MOVE THIS STUFF TO ITS OWN COMPONENT LATER ////////////////////

    

    // RENDER SERVICES SECTIONS //////////////////////////////////////

    var body_header = '';
    if(sections[this.state.currentPage].hasOwnProperty('Services')){


      body_header = (<About
        sections = {sections}
        currentPage = {this.state.currentPage}
      />);

    }else if(String(this.state.currentPage) == "Team"){

      body_header = (
      <div className="Body-header hidden">
        <div className="Half-section Team">
          <h1>
            {sections[this.state.currentPage].Title}
          </h1>
          <p dangerouslySetInnerHTML={{__html: sections[this.state.currentPage].Description}}></p>
        </div>
      </div>);


    }else if(String(this.state.currentPage) == "About"){
      body_header = (
      <div className="Body-header">
        <div className="Half-section About">
          <div className="hidden">
          <h1>
            {sections[this.state.currentPage].Title}
          </h1>
          <p>{sections[this.state.currentPage].Description}<br/><br/><b>That's where we come in.</b><br/><br/></p>
          </div>
          <div className="Quote-text hidden"><div className="leftCorner"></div><div className="rightCorner"></div><span className="blue">We’re one of the few creative firms that understands the product development process from end to end.</span> Our product background means we run our creative process the same way great product teams do – we’re lean, we iterate, and we always measure the results.</div>
        </div>
        <div id="sketch_wrapper"><Product_sketch_svg/></div>
      </div>);
    }
    //<img src={product_sketch} className="Product-sketch hidden"/>


    // RENDER PROJECTS //////////////////////////////////////

    var App_body_section = "";
    if(sections[this.state.currentPage].hasOwnProperty('Projects')){
      var projects = sections[this.state.currentPage].Projects.map((item, key) =>
      <div className="Project Col_3" onClick={(e) => this.toggleProject(e,item)} key={key}>
        <img className="Img" src={Project_Images[item+"_thumb.png"]} />
        <div className="Title">
          <div className="Name"><div className="Logo" style={{backgroundImage: `url(${Project_Images[item+"_logo.png"]})`}}></div>{Projects[item].Name}</div>
        </div>
      </div>);

      App_body_section = (<section className="App-body" id="App-body">
      <div className="Page-container">
        {body_header}
        <div className="Body-projects">
            <h2>{sections[this.state.currentPage].BodyHeaderText}</h2>
            <div className="Projects">
              {projects}
            </div>
        </div>
      </div>
      </section>);

    }else if(this.state.currentPage == "About"){

      /*var projects = Object.keys(Projects).map((item, key) =>
      <div className="Project" onClick={(e) => this.toggleProject(e,item)} key={key}>
        <img className="Img" src={Project_Images[item+"_thumb.png"]}/>
        <div className="Title">
          <div className="Name"><div className="Logo" style={{backgroundImage: `url(${Project_Images[item+"_logo.png"]})`}}></div>{Projects[item].Name}</div>
        </div>
      </div>);*/

      var Services_list = (section_data) => {
        //console.log(section_data);
        
        var Services_list = section_data.Services.map((item, key) =>
        <li key={key}>
          <div className="List-number">{key+1}</div>
          <div className="Name">{item.Title}</div><br/>
          <div className="Description">{item.Description}</div>
        </li>);
        return(Services_list);
      }

      var Projects_object = (section_data) => {
        //console.log(Projects[section_data.Featured[0]]);
        var Projects_section = section_data.Featured.map((item, key) =>
        <div className="Project Col_2" onClick={(e) => this.toggleProject(e,item)} key={key}>
          <img className="Img" src={Project_Images[item+"_thumb.png"]}/>
          <div className="Title">
            <div className="Name"><div className="Logo" style={{backgroundImage: `url(${Project_Images[item+"_logo.png"]})`}}></div>{Projects[item].Name}</div>
          </div>
        </div>);
        return(Projects_section);
      }

      var Work_sections = sections[this.state.currentPage].Sections.map((item, key) =>
      <div className="Work_previews hidden" key={key}>
        <div className="Title">
          <h1 className="h1_2">{sections[item].Title}</h1>
          <h2>Featured</h2>
        </div>
        <div className="left-section">
          <ul className="List Sub-services">{Services_list(sections[item])}</ul>
          <div className="Learn_more" onClick={this.changePage.bind(this,String(item))}>Learn More →</div>
        </div>
        <div className="right-section">
          {Projects_object(sections[item],this)}
        </div>
      </div>);

      App_body_section = (<section className="App-body" id="App-body">
      <div className="Page-container">
        {body_header}
        <div className="Body-projects">
            {Work_sections}
        </div>
      </div>
      </section>);

      /*App_body_section = (<section className="App-body" id="App-body">
      <div className="Page-container">
        {body_header}
        <div className="Body-projects">
            <h2>{sections[this.state.currentPage].BodyHeaderText}</h2>
            <div className="Projects">
              {projects}
            </div>
        </div>
      </div>
      </section>);*/

    }else if(this.state.currentPage == "Team"){

      var Members = Object.keys(Team).map((member, key) =>
      <div className="Member" key={key}>
        <img className="Img" src={Team[member].Photo}/>
        <div className="Title">
          <div className="Name">{Team[member].Name}</div>
          <div className="Job_Title">{Team[member].Job_Title}</div>
          <div className="Bio">{Team[member].Bio}</div>
        </div>
      </div>);

      App_body_section = (<section className="App-body" id="App-body">
      <div className="Page-container">
        {body_header}
        <div className="Body-team">
            <div className="Members">
              {Members}
            </div>
        </div>
      </div>
      </section>);
      
    }


    // RENDER NAV //////////////////////////////////////

    
    var Nav_Sections = {
      About:{
        Title:"Our Work"
      },
      Product:{
        Title:"Product Design"
      },
      Marketing:{
        Title:"Marketing & Ads"
      },
      Film:{
        Title:"Brand & Storytelling"
      },
      Team:{
        Title:"Our Team"
      }
    };



    /*var Nav_Sections = {
      About:{
        Title:"Our Work"
      },
      Product:{
        Title:"Product"
      },
      Marketing:{
        Title:"Marketing"
      },
      Film:{
        Title:"Film & Animation"
      },
      Branding:{
        Title:"Branding"
      },
      Website:{
        Title:"Website"
      },
      Team:{
        Title:"Our Team"
      }
    };*/

    var Main_Nav = Object.keys(Nav_Sections).map((item, key) =>
    <div className={"Menu-item"+(item==this.state.currentPage?" active":"")} data-value={item} onClick={this.changePage.bind(this,String(item))}>
    {Nav_Sections[item].Title}
  </div>);


    ////////////////////////////////////
    // REACT OUTPUT ////////////////////
    ////////////////////////////////////
    
    //<img className="Cover-img" src={cover_img}/>

    return (
      <div className="App">
          <Project_Viewer
           toggleProject={this.toggleProject.bind(this)}
           projectIsOpen={this.state.projectIsOpen}
           project_images={Project_Images}
           current_project={this.state.currentProject}
           project_data={Projects[this.state.currentProject]}/>
          <header className="App-header">
          <div id='videoWrap' dangerouslySetInnerHTML={{__html:`<video autoPlay muted loop id="App-header-video" class="wait">
              <source src="${require('./video/REEL_CUT_2.mp4')}" type="video/mp4"/>
              Your browser does not support HTML5 video.
          </video><div id="videoFade"></div>`}}></div>
            <div className="Page-container">
              <img src={logo} className="App-logo" alt="logo" />
              <p className="Tag-line">Getting you from <span className="hello_code">Hello World<span className="code_underscore">_</span></span> to <span className="hello_brand">Hello World</span></p>
              <div className="Intro-text"><b>Hellopress is a multidisciplinary creative studio that knows how <br/>to take products from zero to one.</b><p>From the first line of code to shipped product, we give fast-moving teams the creative firepower they need to get their product off the ground and into the world.</p></div>
            </div>
          </header>
          <div id = "Nav_wrapper">
          <section className="App-menu" id = "Main_nav">
            <div className="Page-container">
              <div className="Menu-inner">
                {Main_Nav}
              </div>
            </div>
          </section>
          </div>
          {App_body_section}
          <section className="Foot-quote hidden">
            <div className="Page-container">
              <div className="Quote-text hidden"><div className="leftCorner"></div><div className="rightCorner"></div><span className="blue">Want to know more? </span> We love discussing new projects. Just shoot us a message below.</div>
            </div>
          </section>
          <div className="Clients-section hidden">
            <div className="Page-container">
              <div className="Title">Who we've worked with</div>
              <div className="Past-clients" style={{backgroundImage: `url(${Past_Clients})` }}></div>
              <div className="Past-clients-mobile" style={{backgroundImage: `url(${Past_Clients_Mobile})` }}></div>
            </div>
          </div>
          <section className="App-footer hidden">
            <div className="Page-container">
                <div className="Half-section Contact-overview">
                  <h1>
                    Let's work together
                  </h1>
                  <p>We're passionate about bringing the creativity & rigor that it takes make great ideas come to life. If you are too, call or send us an email to get started.</p>
                </div>
                <div className="Half-section">
                  <form action="https://formspree.io/xvoqogoa" method="post" className="Contact-form">
                      <input type="text" name="Name" placeholder="First & Last Name" required/>
                      <input type="text" name="Company" placeholder="Company Name"/>
                      <input type="email" name="Email" placeholder="Email" required/>
                      <input type="phone" name="Phone" placeholder="Phone"/>
                      <textarea name="message" placeholder="Your message..." required></textarea>
                      <input type="submit" value="Send it"/>
                  </form>
                </div>
                <div className="Footer-notes">
                  <div className="left">© 2015 - 2020 Hellopress. All Rights Reserved</div>
                  <div className="right">Designed & Developed in San Francisco</div>
                </div>
            </div>
          </section>
      </div>
    );
  }
}





export default App;




////////////////////////////////////
// EXTRA FUNCTIONS /////////////////
////////////////////////////////////


function setURL(pageName){
  console.log('Setting URL...');
  window.history.pushState({
    id: pageName
  }, pageName+' | Hellopress', pageName);
}






window.addEventListener("load", function(){
  document.getElementById("App-header-video").play();
  document.getElementById("App-header-video").classList.remove('wait');
  /*document.getElementById("App-header-video").addEventListener('loadeddata', function() {
    
  }, false);*/
});





  var elements;
  var windowHeight;

  function init() {
    windowHeight = window.innerHeight;
  }

  function checkPosition() {
    var navbar = document.getElementById("Main_nav");
    var navwrapper = document.getElementById("Nav_wrapper");
    if(typeof(navbar) != 'undefined' && navbar != null){
      if(window.scrollY > navwrapper.offsetTop){
        navbar.classList.add("sticky");
      }else{
        navbar.classList.remove("sticky");
      }
    } 

    elements = document.querySelectorAll('.hidden');
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      var positionFromTop = elements[i].getBoundingClientRect().top;

      if (positionFromTop - windowHeight + 150 <= 0) {
        element.classList.add('fade-in-element');
        element.classList.remove('hidden');
        if(element.classList.contains('Product-sketch')){
          fade_in_sketch('Product-sketch');
          //console.log('running');
        }
        /*setTimeout(() => {
          element.classList.remove('fade-in-element');
        }, 1500);*/
      }
    }
  }

  window.addEventListener('scroll', checkPosition);
  window.addEventListener('resize', init);

  init();
  checkPosition();





  

  function fade_in_sketch(thisClass){
    for (var i = 1; i < 15; i++) {
      var element_class_name = String("T_"+i);
      //console.log(element_class_name);
      var elements = document.getElementsByClassName(element_class_name);
      if(elements.length > 0 && elements !== undefined){
        var element = elements[0];
        do_set_timeout(element, i);
      }

      element_class_name = String("O_"+i);
      elements = document.getElementsByClassName(element_class_name);
      if(elements.length > 0 && elements !== undefined){
        element = elements[0];
        do_set_timeout(element, i);
      }
      
      element_class_name = String("A_"+i);
      elements = document.getElementsByClassName(element_class_name);
      if(elements.length > 0 && elements !== undefined){
        element = elements[0];
        do_set_timeout(element, i);
      }

    }
  }

  function do_set_timeout(element, i){
    var time_multiplier = i;
    if(i>11){
      time_multiplier +=3;
    }
    if(i>13){
      time_multiplier +=5;
    }
    setTimeout(function(){ 
      element.classList.add('sketch_visible');
      element.classList.remove('sketch_hidden');
    }, 130*time_multiplier+500);
    /*setTimeout(function(){ 
      element.classList.remove('SVGTEXT');
    }, 200*time_multiplier+300);*/
  }
  