import React from 'react';
import './Project-Viewer.css';
import mira_logo from './img/mira-logo-mini.png';
//import './App.css';

var mira_logo_bg = {backgroundImage: `url(${mira_logo})`};

/*function Project() {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  return (
    <div className="Project-viewer">
      
    </div>
  );
}*/
/*
var ProjectViewer = React.createClass({
  propTypes: {
    // the StyledButton requires a clickHandler
    clickHandler: React.PropTypes.func.Required,
    // and I guess the text can be seen as required as well
    text: React.PropTypes.string.required
  },
  render: function() {
    // as you are sure you have a clickHandler, you can just reference it directly from the props
    return <button type="button" onClick={this.props.clickHandler}>{this.props.text}</button>;
  }
});*/

class About extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    
    
    var items = this.props.sections[this.props.currentPage].Services.map((item, key) =>
      <li key={key}>
        <div className="List-number">{key+1}</div>
        <div className="Name">{item.Title}</div><br/>
        <div className="Description">{item.Description}</div>
      </li>);
      
      
      return <div className="Body-header hidden">
        <div className="Half-section Service-overview">
          <h1>
            {this.props.sections[this.props.currentPage].Title}
          </h1>
          <p dangerouslySetInnerHTML={{__html: this.props.sections[this.props.currentPage].Description}}></p>
        </div>
        <div className="Half-section Service-breakdown">
          <h2>
          Our Services
          </h2>
          <ul className="List Sub-services">
            {items}
          </ul>
        </div>
      </div>;



    /*
    return <div className={"Project-viewer"+" "+this.props.projectIsOpen}>
      <div className="Background" onClick={(e) => this.props.toggleProject(e,false)}></div>
      <div className="Page-container">
        <div className="Project-container">
          <header className="Project-header" style={{backgroundColor:"#"+project_data.ProjectColor}}>
            <div className="Title">
              <div className="Name"><div className="Logo" style={{backgroundImage: `url(${this.props.project_images[this.props.current_project+"_logo.png"]})`}}></div>{project_data.Name}</div>
            </div>
            <img className="Img" src={this.props.project_images[this.props.current_project+"_thumb.png"]}/>
            <div className="Description">{project_data.Description}</div>
          </header>
          <section className="Project-section">
            {video_player}
          <div className="Half-section">
            <h2>
              The Brief
            </h2>
            <div className="Description">{project_data.Brief}</div>
          </div>
          {work_overview}
          </section>
        </div>
      </div>
    </div>;*/
  }
}


export default About;
